import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { Box, TextField, Typography, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUpdateEventMutationQuery } from 'src/apis/hook/event';
import { useAuthenticated } from 'src/apis/hook/user';
import { useSimpleAlert } from 'src/store/ui';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AssetSelectDialog } from '../../components/AssetSelectDialog';
import { colors } from 'src/theme/colors';
import { Event, Asset } from 'src/apis/type';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { convertMinutesToTime } from 'src/apis/ui';
import { useEventListStore } from 'src/store/event';

export const UpdateEventEditor: FC = () => {
    const { selectedEvent } = useEventListStore();
    const { mutateAsync: updateEvent } = useUpdateEventMutationQuery();
    const { data: authenticated } = useAuthenticated();
    const { alert: simpleAlert } = useSimpleAlert();
    const [startTime, setStartTime] = useState(selectedEvent?.startTime || 0);
    const [date, setDate] = useState(selectedEvent?.date ? dayjs(selectedEvent.date) : dayjs());
    const [assetSelectDialogOpen, setAssetSelectDialogOpen] = useState(false);

    const [address, setAddress] = useState(selectedEvent?.address || '');
    const [addressDesc, setAddressDesc] = useState(selectedEvent?.addressDesc || '');

    const navigate = useNavigate();

    const { t } = useTranslation();
    const [name, setName] = useState(selectedEvent?.name);
    const [desc, setDesc] = useState(selectedEvent?.desc);
    const [headCount, setHeadCount] = useState(selectedEvent?.headCount);

    const [contact, setContact] = useState(selectedEvent?.contact);
    const [moreInfo, setMoreInfo] = useState<string>(selectedEvent?.moreInfo || '');
    const [link, setLink] = useState<string>(selectedEvent?.link || '');

    const hiddenMainFileInputRef = useRef(null);

    const [mainExistImageUrl, setMainExistImageUrl] = useState<string>(
        selectedEvent?.mainImage ? selectedEvent.mainImage : ''
    );

    const [existImageUrls, setExistImageUrls] = useState<string[]>(selectedEvent?.images ? selectedEvent.images : []);

    const [imageFiles, setImageFiles] = useState<any[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);

    const [mainImageFile, setMainImageFiles] = useState<any>();
    const [mainImageUrl, setMainImageUrl] = useState<string>(selectedEvent?.mainImage ? selectedEvent.mainImage : '');

    const handleMainFileAttachClick = (event: any) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0];
            setMainImageFiles(file);
            reader.onloadend = (e) => {
                if (e.target) {
                    setMainImageUrl(e.target.result as string);
                }
            };
            setMainExistImageUrl(''); // 기존 파일은 초기화 시켜 버린다.
            reader.readAsDataURL(file);
        }
    };

    const resetPage = () => {
        setName('');
        setDesc('');
        setContact('');
        setMoreInfo('');
        setImageFiles([]);
        setImageUrls([]);
        setMainImageFiles(undefined);
        setMainImageUrl('');
        setSelectedAsset(undefined);
    };

    const updateValue = async (value: string, mode: string) => {
        if (mode === 'name') {
            setName(value);
        } else if (mode === 'desc') {
            setDesc(value);
        } else if (mode === 'contact') {
            setContact(value);
        } else if (mode === 'moreInfo') {
            setMoreInfo(value);
        }
    };

    const tryUpdateEvent = async () => {
        if (authenticated) {
            if (name && name.length > 0 && desc && desc.length) {
                const formData = new FormData();

                imageFiles.forEach((item, index) => {
                    formData.append('imageFiles', item, `${index}-${item.name}`);
                });
                if (mainImageFile) {
                    formData.append('mainImageFiles', mainImageFile, mainImageFile.name);
                }

                const eventInfo: Event = {
                    _id: selectedEvent?._id,
                    name: name,
                    desc: desc,
                    contact: contact,
                    headCount: headCount,
                    moreInfo,
                    date: date.toDate(),
                    startTime,
                    address,
                    addressDesc,
                    link: link,
                    asset: selectedAsset
                };
                formData.append('event', JSON.stringify(eventInfo));

                formData.append(
                    'images',
                    JSON.stringify({
                        mainImage: mainExistImageUrl
                    })
                );

                const result = await updateEvent(formData);
                if (result) {
                    simpleAlert(t('names:notification'), t('names:eventUpdateSuccess'), 'info');
                    resetPage();
                    navigate('/event');
                }
            } else {
                simpleAlert(t('names:error'), t('names:missingEssential'), 'error');
            }
        } else {
            simpleAlert(t('names:error'), t('names:logoutStatus'), 'error');
            navigate('/login');
        }
    };

    const removeExistImage = (imageUrl: string) => {
        const currentUrl = imageUrl;
        const eImageUrls = existImageUrls.filter((url) => url !== currentUrl);
        if (eImageUrls.length > 0) {
            setExistImageUrls(eImageUrls);
        } else {
            setExistImageUrls([]);
        }
    };

    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(selectedEvent?.asset);
    return (
        <>
            <AssetSelectDialog
                open={assetSelectDialogOpen}
                onClose={() => {
                    setAssetSelectDialogOpen(false);
                }}
                onSelect={(asset) => {
                    console.log('asset', asset);
                    setSelectedAsset(asset);
                }}
            />

            <Box width="100%" alignItems="center" display="flex" flexDirection="column" maxWidth="800px">
                <Grid container justifyContent="center" spacing={1} sx={{ p: 1 }}>
                    <Grid item xs={6}>
                        <TextField
                            sx={{ width: '100%' }}
                            id="outlined-basic"
                            label={t('names:name')}
                            variant="outlined"
                            size="small"
                            value={name}
                            onChange={(e) => updateValue(e.target.value, 'name')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center" width="100%">
                            <TextField
                                sx={{ width: '100%' }}
                                id="outlined-basic"
                                label={t('names:maxValue')}
                                variant="outlined"
                                size="small"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">명</InputAdornment>
                                }}
                                value={headCount}
                                onChange={(e) => setHeadCount(Number(e.target.value))}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box width="100%" p={1}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:description')}
                        variant="outlined"
                        size="small"
                        value={desc}
                        sx={{ width: '100%' }}
                        onChange={(e) => updateValue(e.target.value, 'desc')}
                    />
                </Box>
                <Box width="100%" p={1} display="flex" alignItems="center">
                    <Box
                        width="calc(100% - 113px)"
                        height="40px"
                        sx={{ background: colors.mainBlue }}
                        border="1px solid #BABABA"
                        borderRadius={3}
                        alignItems="center"
                        p={1}
                        display="flex"
                        color={'white'}
                        gap={1}
                    >
                        {selectedAsset && (
                            <>
                                <Box width="20%" overflow="hidden">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.name}
                                    </Typography>
                                </Box>
                                <Box width="20%">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.desc}
                                    </Typography>
                                </Box>
                                <Box width="50%" overflow="hidden">
                                    <Typography
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {selectedAsset.address}
                                    </Typography>
                                </Box>
                                <Box width="10%" overflow="hidden">
                                    <IconButton
                                        onClick={() => {
                                            setSelectedAsset(undefined);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box width="115px" ml={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon fontSize="small" />}
                            onClick={() => {
                                setAssetSelectDialogOpen(true);
                            }}
                        >
                            {t('names:selectAssets')}
                        </Button>
                    </Box>
                </Box>
                <Box width="100%" p={1}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:address')}
                        variant="outlined"
                        size="small"
                        value={address}
                        sx={{ width: '100%' }}
                        rows={4}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </Box>
                <Box width="100%" p={1} mb={1}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:addressDesc')}
                        variant="outlined"
                        size="small"
                        value={addressDesc}
                        sx={{ width: '100%' }}
                        rows={4}
                        onChange={(e) => setAddressDesc(e.target.value)}
                    />
                </Box>
                <Box width="100%" p={1}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:moreInfo')}
                        variant="outlined"
                        size="small"
                        value={moreInfo}
                        sx={{ width: '100%' }}
                        multiline
                        rows={4}
                        onChange={(e) => updateValue(e.target.value, 'moreInfo')}
                    />
                </Box>
                <Box width="100%" p={1}>
                    <TextField
                        id="outlined-basic"
                        label={t('names:link')}
                        variant="outlined"
                        size="small"
                        value={link}
                        sx={{ width: '100%' }}
                        type="url"
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Box>

                <Box mt={1} width="100%" p={1}>
                    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
                        <Box width="100%">
                            <TextField
                                id="outlined-basic"
                                label={t('names:contact')}
                                variant="outlined"
                                size="small"
                                value={contact}
                                sx={{ width: '100%' }}
                                onChange={(e) => updateValue(e.target.value, 'contact')}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box mt={1} width="100%" p={1}>
                    <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                    <DatePicker
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: '100%' }}
                                        value={date}
                                        label={t('names:date')}
                                        disablePast
                                        onChange={(date) => {
                                            if (date) {
                                                setDate(date);
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                                    <TimePicker
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: '100%' }}
                                        label={t('names:startTime')}
                                        ampm={false}
                                        value={convertMinutesToTime(startTime)}
                                        onChange={(date) => {
                                            if (date) {
                                                const hour = date.get('hour');
                                                const minute = date.get('minute');
                                                const totalMinutes = hour * 60 + minute;
                                                setStartTime(totalMinutes);
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box mt={2} width="100%" p={1}>
                    <Button
                        color="inherit"
                        size="small"
                        sx={{ border: 'solid', borderColor: '#CCCEDD', marginRight: '11px' }}
                        onClick={() => {
                            if (hiddenMainFileInputRef && hiddenMainFileInputRef.current) {
                                const fileInput = hiddenMainFileInputRef.current as HTMLInputElement;
                                fileInput.click();
                            }
                        }}
                        startIcon={<AttachFileIcon fontSize="small" color="primary" />}
                    >
                        <Typography fontSize="14px" color="black">
                            {t('names:mainImageFile')}
                        </Typography>
                    </Button>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={hiddenMainFileInputRef}
                        onChange={handleMainFileAttachClick}
                        accept="image/*"
                    />
                </Box>
                <Box width="100%" p={1} display="flex">
                    <Box
                        width="150px"
                        height="150px"
                        display="flex"
                        justifyContent="right"
                        sx={{
                            border: 'solid 1px #CCCEDD',
                            backgroundImage: mainImageUrl && mainImageUrl.length > 0 ? `url(${mainImageUrl})` : '',
                            backgroundSize: 'cover'
                        }}
                    ></Box>
                </Box>

                <Box width="100%" p={2} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            tryUpdateEvent();
                        }}
                    >
                        {t('names:updateEvent')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
