import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APIEvent } from 'src/apis/event';
import { APIError } from 'src/apis/error';
import { HttpStatusCode } from 'axios';
import { Events } from 'src/apis/type';

export const useCreateEventMutationQuery = () => {
    return useMutation({
        mutationKey: ['@event/mutation.create'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIEvent.createAdminEvent(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateEventMutationQuery = () => {
    return useMutation({
        mutationKey: ['@event/mutation.update'],
        mutationFn: async (params: FormData) => {
            try {
                const result = await APIEvent.updateAdminEvent(params);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateEventAvailableMutationQuery = () => {
    return useMutation({
        mutationKey: ['@event/isAvailable/mutation.update'],
        mutationFn: async ({ id, isAvailable }: { id: string; isAvailable: boolean }) => {
            try {
                console.log(id, isAvailable);
                const result = await APIEvent.updateEventAvailableStatus(id, isAvailable);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                } else {
                    return false;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useUpdateEventClosedMutationQuery = () => {
    return useMutation({
        mutationKey: ['@event/closed/mutation.update'],
        mutationFn: async ({ id, isClosed }: { id: string; isClosed: boolean }) => {
            try {
                const result = await APIEvent.updateEventClosedStatus(id, isClosed);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};

export const useEventsQuery = (page: number, limit: number) => {
    return useQuery({
        queryKey: ['@event/authenticated', page, limit],
        queryFn: async () => {
            try {
                if (limit > 0) {
                    const result = await APIEvent.getEvents(page, limit);
                    if (result.status == 200) {
                        return result.data as Events;
                    }
                }
                return [];
            } catch (e) {
                return [];
            }
        },

        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 30000
    });
};

export const useEventsAllQuery = (search?: string, startDate?: Date, endDate?: Date, page?: number, limit?: number) => {
    return useQuery({
        queryKey: ['@event/authenticated/all', search, startDate, endDate, page, limit],
        queryFn: async () => {
            try {
                let startDateText = '';
                if (startDate) {
                    startDateText = startDate.toISOString().split('T')[0];
                }
                let endDateText = '';
                if (endDate) {
                    endDateText = endDate.toISOString().split('T')[0];
                }
                const result = await APIEvent.getEventsAll(search, startDateText, endDateText, page, limit);
                if (result.status == 200) {
                    return result.data as Events;
                }
                return [];
            } catch (e) {
                return [];
            }
        },
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: 30000
    });
};

export const useDeleteEventMutationQuery = () => {
    return useMutation({
        mutationKey: ['@event/mutation.delete'],
        mutationFn: async (id: string) => {
            try {
                const result = await APIEvent.deleteEvent(id);
                if (result.status === HttpStatusCode.Ok) {
                    return true;
                }
            } catch (e: any) {
                if (Object.keys(e).includes('response')) {
                    throw new APIError(e.response.status, e.response.statusText);
                } else {
                    throw new APIError(HttpStatusCode.InternalServerError, 'Unknown Error');
                }
            }
        }
    });
};
